.MuiGrid-container {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  box-sizing: border-box;
}

.MuiFab-label {
  font-size: 13px;
  max-width: max-content;
}

.MuiDrawer-paper {
  height: 92% !important;
}
.MuiList-padding {
  padding-top: 0px !important;
}

.MuiAutocomplete-inputRoot {
  flex-wrap: nowrap;
  padding: 0 !important;
}

[type="button"] {
  cursor: pointer;
  outline: none !important;
}
